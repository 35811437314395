// returns activeLessonId: string, activeModule: object, activeModuleIndex: num
export const getActiveLesson = (modules, completedLessons) => {
  if (modules.length === 0)
    return {
      activeLesson: "",
      activeModule: null,
      activeModuleIndex: 0,
      activeLessonInModuleIndex: 0,
    };
  for (var i in modules) {
    for (var j in modules[i].lessons) {
      var lessonId = modules[i].lessons[j]._id;
      if (completedLessons.indexOf(lessonId) === -1) {
        return {
          activeLessonId: lessonId,
          activeModule: modules[i],
          activeModuleIndex: parseInt(i),
          activeLessonInModuleIndex: parseInt(j),
        };
      }
    }
  }

  const module = modules[0];
  const lesson = module.lessons[0];
  return {
    activeLessonId: lesson._id,
    activeModule: module,
    activeModuleIndex: 0,
    activeLessonInModuleIndex: 0,
  };
};
