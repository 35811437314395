export const findLessonById = (modules, lessonId) => {
  for (const module of modules) {
    for (const lesson of module.lessons) {
      if (lesson._id === lessonId) {
        return lesson;
      }
    }
  }
  return null;
};
