import { Box, Button, Progress, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MuxPlayer from "@mux/mux-player-react";

export default function HLSPlayer({
  muxPlaybackId,
  playNextLesson,
  externalVideoRef = null,
  initialWatchPos = 0,
}) {
  const videoRef = useRef(null);
  const refToUse = externalVideoRef || videoRef;
  const [countdown, setCountdown] = useState(null);
  const cancelClickedRef = useRef(false);

  const refreshRate = 100;
  const seconds = 5;

  useEffect(() => {
    let video = refToUse.current.media.nativeEl;
    const canvas = document.getElementById("canvasElement");
    const ctx = canvas.getContext("2d");

    if (video) {
      video.addEventListener("play", function () {
        setInterval(() => {
          if (!video.paused && !video.ended) {
            ctx.filter = "blur(10px)";
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          }
        }, 100);
      });
    }
  }, [refToUse]);

  useEffect(() => {
    let video = refToUse.current.media.nativeEl;

    if (!video) return;

    const onVideoEnded = () => {
      let counter = seconds * refreshRate;
      setCountdown(counter);
      cancelClickedRef.current = false;

      const interval = setInterval(() => {
        if (cancelClickedRef.current === true) {
          console.log("Cancel clicked");
          clearInterval(interval);
          setCountdown(null);
          return;
        }
        setCountdown(--counter);
        if (counter <= 0) {
          cancelClickedRef.current = true;
          clearInterval(interval);
          setCountdown(null);
          playNextLesson();
        }
      }, 10);
    };

    video.addEventListener("ended", onVideoEnded);

    return () => {
      video.removeEventListener("ended", onVideoEnded);
      // Add your other cleanup logic here if needed.
    };
  }, [refToUse, playNextLesson]);

  return (
    <Box
      h="100%"
      w="100%"
      display={{ base: "block", md: "flex" }}
      justifyContent={"center"}
      position="relative" // make sure this is set to relative
    >
      <MuxPlayer
        ref={refToUse}
        streamType="on-demand"
        playbackId={muxPlaybackId}
        objectFit="cover"
        borderRadius="20"
        className="mux-player"
        id="mux-player"
        startTime={initialWatchPos}
        autoPlay={true}
        style={{
          borderRadius: 20,
          overflow: "hidden",
          height: "100%",
        }}
      />
      {countdown !== null && (
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          backgroundColor="rgba(0,0,0,0.5)"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div
            style={{ color: "white", fontSize: "2rem", marginBottom: "20px" }}
          ></div>
          <Box
            position="relative"
            width="50%"
            maxW={"250px"}
            borderRadius="16px"
            borderWidth={"1px"}
            borderColor="whiteAlpha.400"
          >
            <Progress
              value={
                ((seconds * refreshRate - countdown) /
                  (seconds * refreshRate)) *
                100
              }
              thickness={"10px"}
              width="100%"
              height={"50px"}
              colorScheme="whiteAlpha"
              trackColor="white"
              bg="transparent"
              borderLeftRadius={"16px"}
              size="lg"
            />

            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontWeight="bold"
              width="100%"
              textAlign={"center"}
              color="white"
            >
              <Text>
                Next video in {(countdown / refreshRate).toFixed(0)} seconds
              </Text>
            </Box>
          </Box>
          <Button
            _hover={{ bg: "whiteAlpha.400" }}
            size="md"
            width="50%"
            maxW={"250px"}
            bg="transparent"
            borderRadius="16px"
            borderWidth={"1px"}
            borderColor="whiteAlpha.400"
            color="white"
            onClick={() => (cancelClickedRef.current = true)}
            style={{ marginTop: "10px" }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
}
