import React from "react";
import {
  Box,
  Flex,
  Text,
  List,
  ListItem,
  ListIcon,
  Tag,
  HStack,
  VStack,
  Image,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
} from "@chakra-ui/react";
import icStatus from "../../assets/ic-status.svg";
import { CircularProgress } from "@chakra-ui/react";
import ReactGA from "react-ga4";

export default function CourseNav({
  isOpen,
  onClose,
  progressState,
  course,
  onClickLesson,
  lesson,
  children,
}) {
  const [modules, setModules] = React.useState([]);

  React.useEffect(() => {
    setModules(course.modules);
  }, [course]);

  return (
    <Flex h={"100%"}>
      <SidebarContent
        w={isOpen ? 600 : 0}
        onClose={onClose}
        isOpen={isOpen}
        modules={modules}
        onClickLesson={onClickLesson}
        progressState={progressState}
        lesson={lesson}
      />
      <Box
        h="100%"
        w="100%"
        justifyContent={"center"}
        display={{ base: isOpen ? "none" : "block", md: "flex" }}
      >
        {children}
      </Box>
    </Flex>
  );
}

const ModuleStatusIcon = ({ completedLessonsNum, totalLessons }) => {
  return (
    <>
      {completedLessonsNum === totalLessons ? (
        <ListIcon
          w={"100%"}
          as={() => (
            <Image
              src={icStatus}
              boxSize="25px"
              mt={1}
              mr={"12px"}
              marginTop={"2px"}
            />
          )}
          color="green.500"
        />
      ) : (
        <CircularProgress
          value={(completedLessonsNum / totalLessons) * 100}
          size={"25px"}
          mr={"12px"}
          marginTop={"3px"}
          thickness={"10px"}
          trackColor="whiteAlpha.400"
          color="white"
        />
      )}
    </>
  );
};

const LessonStatusIcon = ({ progressState, lesson }) => {
  const watchedLessons = progressState.watchedLessons;

  const watchedLesson = watchedLessons.find((wl) => wl.lessonId === lesson._id);

  const completed = watchedLesson?.completed;

  const progress = watchedLesson
    ? (watchedLesson.watchPositionSeconds / watchedLesson.durationSeconds) * 100
    : 0;

  return (
    <>
      {completed ? (
        <ListIcon
          w={"100%"}
          as={() => <Image src={icStatus} boxSize="25px" mt={1} mr={"12px"} />}
          color="green.500"
          style={{
            marginTop: 10,
            marginRight: 10,
          }}
        />
      ) : (
        <CircularProgress
          value={progress}
          size={"25px"}
          mr={"12px"}
          //marginTop={"10px"}
          thickness={"10px"}
          trackColor="whiteAlpha.400"
          color="white"
        />
      )}
    </>
  );
};

function getTotalDuration(module) {
  let total = 0;
  module.lessons.forEach((lesson) => {
    total += lesson.durationSeconds || 60 * 5; // assume 5 minutes if duration doesn't exist
  });
  return total;
}

const ModuleHeader = ({ progressState, module }) => {
  const watchedLessons = progressState.watchedLessons;
  const completedLessonsNum = module.lessons.filter(
    (lesson) =>
      watchedLessons.find((wl) => wl.lessonId === lesson._id)?.completed ===
      true
  ).length;
  const totalLessons = module.lessons.length;

  const moduleDurationMinutes = (getTotalDuration(module) / 60).toFixed(0);

  return (
    <Flex p={5} w={"100%"}>
      <ModuleStatusIcon
        completedLessonsNum={completedLessonsNum}
        totalLessons={totalLessons}
      />
      <VStack w={"100%"}>
        <HStack justifyContent={"space-between"} w={"100%"}>
          <Text textStyle="moduleHeader">{module.title}</Text>
          <Tag
            variant="solid"
            textStyle={"textSm"}
            bg="whiteAlpha.100"
            minW="fit-content"
            borderRadius={20}
          >
            {moduleDurationMinutes + " mins"}
          </Tag>
        </HStack>
        <HStack w="full">
          <Tag
            variant="solid"
            textStyle={"textSm"}
            bg="whiteAlpha.100"
            borderRadius={20}
          >
            {totalLessons} lessons
          </Tag>
          <Tag
            variant="solid"
            textStyle={"textSm"}
            bg="whiteAlpha.100"
            borderRadius={20}
          >
            {((completedLessonsNum / totalLessons) * 100).toFixed(1)}% completed
          </Tag>
        </HStack>
      </VStack>
    </Flex>
  );
};

const SidebarContent = ({
  modules,
  isOpen,
  onClose,
  onClickLesson,
  progressState,
  lesson,
  ...rest
}) => {
  return (
    <Box
      borderWidth={isOpen ? "1px" : 0}
      borderRadius={20}
      transition={"all .3s ease"}
      bg="blackAlpha.300"
      borderColor={"whiteAlpha.400"}
      backdropFilter="blur(5px)"
      marginRight={{ base: 0, md: isOpen ? 5 : 0 }}
      overflow={"scroll"}
      {...rest}
    >
      <Flex w="100%">
        <List spacing={3} w={"100%"}>
          <ListItem w={"100%"}>
            <Accordion allowMultiple w={"100%"}>
              {modules.map((item, index) => (
                <AccordionItem
                  key={index}
                  borderColor={"whiteAlpha.200"}
                  w={"100%"}
                >
                  <AccordionButton
                    _hover={{ bg: "whiteAlpha.300" }}
                    textAlign={"left"}
                    p={0}
                    w={"100%"}
                    onClick={() => {
                      ReactGA.event({
                        category: "Module",
                        action: "Clicked",
                        label: item.title,
                        value: index,
                      });
                    }}
                  >
                    <ModuleHeader progressState={progressState} module={item} />
                  </AccordionButton>

                  <AccordionPanel pb={4} p={0} color={"white"}>
                    {
                      <List p={0} w={"100%"}>
                        {item.lessons.map((moduleLesson, index) => {
                          let bgColor = "none";
                          if (moduleLesson._id === lesson._id)
                            bgColor = "whiteAlpha.500";
                          return (
                            <ListItem
                              key={index}
                              w={"100%"}
                              p={0}
                              _hover={{ bg: "whiteAlpha.200" }}
                              //borderRadius={20}
                              bgColor={bgColor}
                              cursor="pointer" // Add this line
                            >
                              <Flex
                                paddingX={5}
                                paddingY={2}
                                w={"100%"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                onClick={() => onClickLesson(moduleLesson)}
                              >
                                <LessonStatusIcon
                                  lesson={moduleLesson}
                                  progressState={progressState}
                                />
                                <Image
                                  src={moduleLesson.thumbnail}
                                  w={"86px"}
                                  h={"48px"}
                                  mt={1}
                                  mr={"12px"}
                                  borderRadius={"8px"}
                                />
                                <VStack w={"100%"} justifyContent={"center"}>
                                  <HStack
                                    justifyContent={"space-between"}
                                    w={"100%"}
                                  >
                                    <Text textStyle="moduleHeader">
                                      {moduleLesson.title}
                                    </Text>
                                    <Tag
                                      variant="solid"
                                      textStyle={"textSm"}
                                      bg="whiteAlpha.100"
                                      minW="fit-content"
                                      borderRadius={20}
                                    >
                                      {moduleLesson?.durationSeconds
                                        ? (
                                            moduleLesson.durationSeconds / 60
                                          ).toFixed(0) + " mins"
                                        : "5 mins"}
                                    </Tag>
                                  </HStack>
                                </VStack>
                              </Flex>
                            </ListItem>
                          );
                        })}
                      </List>
                    }
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </ListItem>
        </List>
      </Flex>
    </Box>
  );
};
