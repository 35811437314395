import { Box, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import CourseNav from "./CourseNav";
import withAuthentication from "../auth/withAuthentication";
import Header from "./Header";
import fetchProgressAndDispatch from "../contexts/Progress/fetchProgressAndDispatch";
import fetchUserAndDispatch from "../contexts/User/fetchUserAndDispatch";
import React, { useContext, useEffect } from "react";
import AuthUserContext from "../auth/AuthUserContext";
import { ProgressContext } from "../contexts/Progress";
import { CourseContext } from "../contexts/Course";
import { UserContext } from "../contexts/User";
import fetchCourseAndDispatch from "../contexts/Course/fetchCourseAndDispatch";
import { useSearchParams } from "react-router-dom";
import { getActiveLesson } from "../CourseViewer/utils/getActiveLesson";
import getCompletedLessonsFromWatched from "../CourseViewer/utils/getCompletedLessonsFromWatched";
import { findLessonById } from "../CourseViewer/utils/findLessonById";
import NewLessonViewer from "./NewLessonViewer";
import { Spinner, Flex } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import "./viewer.css";
import { findNextLesson } from "../CourseViewer/utils/findNextLesson";
function NewViewer() {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const authUser = useContext(AuthUserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlLessonId, setUrlLessonId] = React.useState(
    searchParams.get("lessonId")
  );

  const [progressState, progressDispatch] = React.useContext(ProgressContext);
  const [courseState, courseDispatch] = React.useContext(CourseContext);
  const [userState, userDispatch] = React.useContext(UserContext);

  const [selectedLesson, setSelectedLesson] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    // both progress and course are loaded
    if (
      progressState.watchedLessons !== null &&
      courseState.activeCourse._id !== ""
    ) {
      let activeLesson = getActiveLesson(
        courseState.activeCourse.modules,
        getCompletedLessonsFromWatched(progressState.watchedLessons)
      );

      let initialLesson = findLessonById(
        courseState.activeCourse.modules,
        activeLesson.activeLessonId
      );

      if (!urlLessonId) {
        setUrlLessonId(activeLesson.activeLessonId); // Set lessonId here
        setSearchParams({ lessonId: activeLesson.activeLessonId }); // Update URL
      } else {
        initialLesson = findLessonById(
          courseState.activeCourse.modules,
          urlLessonId
        );
      }
      setSelectedLesson(initialLesson);
      setIsLoading(false);
    }
  }, [progressState, urlLessonId, courseState, setSearchParams]);

  useEffect(() => {
    if (authUser) {
      fetchUserAndDispatch(userDispatch);
      fetchProgressAndDispatch(progressDispatch);
    }
  }, [authUser, userDispatch, progressDispatch]);

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    const user = userState.user;

    if (user.courses && user.courses.length > 0) {
      const courseId = user.courses[0]._id;
      fetchCourseAndDispatch(courseDispatch, courseId);
    }
  }, [userState, courseDispatch]);

  useEffect(() => {
    if (selectedLesson) {
      setUrlLessonId(selectedLesson._id);
    }
  }, [selectedLesson]);

  const onClickLesson = (newLesson) => {
    ReactGA.event({
      category: "Lesson",
      action: "Clicked",
      label: newLesson._id,
    });

    setUrlLessonId(newLesson._id);
    setSelectedLesson(newLesson);
    setSearchParams({ lessonId: newLesson._id });
    if (isMobile) {
      onClose();
    }
  };

  const playNextLesson = () => {
    let newLesson = findNextLesson(
      courseState.activeCourse.modules,
      urlLessonId
    );

    console.log("Playing next lesson", newLesson?._id);

    if (newLesson === null) {
      console.log("No lesson found");
      // no lesson found
      return;
    }

    ReactGA.event({
      category: "Lesson",
      action: "Auto play",
      label: newLesson._id,
    });
    setUrlLessonId(() => newLesson._id);
    setSelectedLesson(() => newLesson);
    setSearchParams({ lessonId: newLesson._id });
  };

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        position="fixed"
        width="100%"
        height="100%"
        zIndex="9999"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <Header
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        lesson={selectedLesson}
      />
      <Box
        px={{ md: "5", base: 0 }}
        pb={{ md: "3", base: 0 }}
        pt="0px"
        h={"100%"}
        maxHeight={{ base: "calc(100vh - 70px)" }}
        w="100%"
        overflowY={{ base: "scroll" }}
      >
        {/* The header height is 60px, so we subtract that from 100vh */}
        <CourseNav
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          progressState={progressState}
          course={courseState.activeCourse}
          onClickLesson={onClickLesson}
          lesson={selectedLesson}
        >
          <NewLessonViewer
            lesson={selectedLesson}
            playNextLesson={() => playNextLesson(urlLessonId)}
          />
        </CourseNav>
        {/* Commented this out as per your code */}
      </Box>
    </>
  );
}

export default withAuthentication(NewViewer);
