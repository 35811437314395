import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { GetUserAccount } from "../api/UserApi";
import { storeData } from "../storage/storage";
import "./styles.css";
import AuthUserContext from "../auth/AuthUserContext";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
// import { OAuthButtonGroup } from "./OAuthButtonGroup";
import { PasswordField } from "./PasswordField";
import ReactGA from "react-ga4";
import Header from "../NewViewer/Header";
import Background from "../Background";
import LoginBackground from "../assets/loginBackground.png";

const NewLoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authUser = useContext(AuthUserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      // authUser has been updated, you can navigate to another page
      navigate("/viewer");
    }
  }, [authUser, navigate]);

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const handleLogin = () => {
    console.log("Trying to login with", email);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        console.log("Signed in with:", user.email);
        return GetUserAccount();
      })
      .then((mongoUser) => {
        console.log(mongoUser);
        const userId = mongoUser._id;
        console.log("User id" + userId);
        storeData("userId", userId);
      })
      .catch((error) => {
        console.log(error);
        alert("Your email or password may be incorrect. Please try again.");
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      // Do whatever you want when the Enter key is pressed
      handleLogin();
    }
  };

  return (
    <Box overflowY={{ base: "scroll", md: "hidden" }} h="100%">
      <Background backgroundImage={LoginBackground} noBlur={true}>
        <Header isLoggedOut={true} />
        <Container
          maxW="lg"
          py={{ base: "12", md: "24" }}
          px={{ base: "0", sm: "8" }}
          alignItems={"flex-start"}
          //display={"flex"}
          m={0}
          ml={{ base: "none", md: "112px" }}
        >
          <Stack spacing="8">
            <Box
              my={{ base: "2" }}
              mx={{ base: "4" }}
              py={{ base: "8", sm: "8" }}
              px={{ base: "8", sm: "10" }}
              //bg={{ base: "transparent", sm: "bg.surface" }}
              boxShadow={{ base: "none", sm: "md" }}
              borderRadius={{ base: "24px", sm: "24px" }}
              bgColor={"white"}
              borderColor={"whiteAlpha.400"}
              borderWidth={"1px"}
            >
              <Stack spacing="6">
                {/* <Logo /> */}
                <Stack spacing="3" textAlign="left">
                  <Heading size="xl">Log in</Heading>
                  <Text>
                    Don't have an account?{" "}
                    <Link href="/signup" textDecoration={"underline"}>
                      Sign up
                    </Link>
                  </Text>
                </Stack>
              </Stack>
              <Stack spacing="6" paddingTop={"16px"}>
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel htmlFor="email">
                      <Flex>
                        <Text mr="4px">Email </Text>
                        <Text>*</Text>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="email"
                      type="email"
                      bgColor="blackAlpha.200"
                      borderColor="blackAlpha.200"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="john@mystartup.com"
                    />
                  </FormControl>
                  <PasswordField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Stack>
                <HStack justify="space-between">
                  <Checkbox defaultChecked>Keep me signed in</Checkbox>
                </HStack>
                <Stack spacing="6">
                  <Button
                    bgImage="linear-gradient(to right, #80EFAC, #80EFEF)"
                    onClick={handleLogin}
                    borderRadius={"12px"}
                  >
                    Log in
                  </Button>
                  <Text textAlign={"center"} fontSize="14px">
                    Forgot your password?{" "}
                    <Link href="#" textDecoration={"underline"}>
                      Reset it
                    </Link>
                  </Text>
                  {/* <HStack>
                <Divider />
                <Text textStyle="sm" whiteSpace="nowrap" color="whiteAlpha.700">
                  or continue with
                </Text>
                <Divider />
              </HStack>
              <OAuthButtonGroup /> */}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Background>
    </Box>
  );
};

export default NewLoginScreen;
