import React, { useState } from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import * as tus from "tus-js-client";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "./style.css";
import HLSPlayer from "./HLSPlayer";

export const LessonUploader = ({ setVideoMetadata, initialVideoUrl }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);
  const [videoId, setVideoId] = useState(
    parseVideoIdFromCloudflareUrl(initialVideoUrl)
  );

  function parseVideoIdFromCloudflareUrl(cloudflareUrl) {
    if (cloudflareUrl === null || cloudflareUrl === undefined) return null;
    const regex = /\/([a-f\d]{32})\//; // Assumes the ID is a 32-character hexadecimal string
    const match = cloudflareUrl.match(regex);
    return match ? match[1] : null;
  }

  function parseVideoIdFromUrl(videoUrl) {
    if (videoUrl === null || videoUrl === undefined) return null;
    const regex = /\/tus\/([a-zA-Z0-9]+)/;
    const match = videoUrl.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  const prefetchGifThumbnail = (url) => {
    const img = new Image();
    img.src = url;
  };

  const generateVideoMetadata = (videoId) => {
    const videoUrl = `https://customer-jxvq7jh5xtsbpvhm.cloudflarestream.com/${videoId}/manifest/video.m3u8`;
    const thumbnailUrl = `https://customer-jxvq7jh5xtsbpvhm.cloudflarestream.com/${videoId}/thumbnails/thumbnail.gif?start=0&end=5`;

    return {
      url: videoUrl,
      thumbnail: thumbnailUrl,
    };
  };

  const handleSetUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      return;
    }

    const upload = new tus.Upload(file, {
      endpoint: "/.netlify/functions/getUploadUrl",
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: (error) => {
        setError(error);
        setHasStarted(false);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        setUploadProgress((bytesUploaded / bytesTotal) * 100);
      },
      onSuccess: () => {
        const videoIdFromUrl = parseVideoIdFromUrl(upload.url);
        const metadata = generateVideoMetadata(videoIdFromUrl);
        setVideoId(videoIdFromUrl);
        setVideoMetadata(metadata);
        setHasStarted(false);

        prefetchGifThumbnail(metadata.thumbnail); // Add this line to prefetch the GIF thumbnail
      },
    });

    //setUpload(upload);
    upload.start();
    setHasStarted(true);
  };

  const discardUploadClicked = () => {
    setVideoMetadata({ url: initialVideoUrl, thumbnail: "" });
    setVideoId(null);
    setHasStarted(false);
  };

  console.log("viudeo url", initialVideoUrl);

  return (
    <>
      {error && <div>error</div>}
      {videoId === null ? (
        <div className="dropzone">
          <Dropzone onDrop={handleSetUpload}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  className="cursor"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <CloudUploadIcon fontSize="large" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 10,
                      }}
                    >
                      Upload Video
                    </div>
                  </div>
                  <div
                    style={{
                      width: "200px",
                      textAlign: "center",
                    }}
                  >
                    {videoId === null && hasStarted && (
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      ) : (
        <div
          className="dropzone"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "fit-content",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignSelf: "flex-end",
            }}
          >
            <HighlightOffIcon fontSize="small" onClick={discardUploadClicked} />
          </div>
          <HLSPlayer url={initialVideoUrl} />
        </div>
      )}
    </>
  );
};
