import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { CreateUserAccount } from "../api/UserApi";
import { storeData } from "../storage/storage";
import "./styles.css";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PasswordField } from "./PasswordField";
import ReactGA from "react-ga4";
import Background from "../Background";
import LoginBackground from "../assets/loginBackground.png";
import Header from "../NewViewer/Header";
import { isTagManagerInitialized } from "../App";
import TagManager from "react-gtm-module";

const SignUp = () => {
  const [socialUrl, setSocialUrl] = useState("");
  const [termsAndService, setTermsAndService] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const validateAccountCreationInfo = () => {
    if (!isValidSocialURL(socialUrl)) {
      alert("Please enter a valid LinkedIn or Twitter URL.");
      return false;
    }

    if (password !== retypePassword) {
      alert("Passwords do not match.");
      return false;
    }

    if (!termsAndService) {
      alert("Please agree to the terms and service.");
      return false;
    }

    return true;
  };

  const handleAccountCreation = () => {
    console.log("Trying to create account with", email, password);

    const isValid = validateAccountCreationInfo(socialUrl);

    if (!isValid) {
      console.log("NOT VALID");
      return;
    }
    console.log("About to create account with ", email, password);
    console.log("auth object", auth);
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        console.log("Account created with:", user.email);
        return CreateUserAccount(user.email, user.uid, socialUrl);
      })
      .then((mongoUser) => {
        console.log(mongoUser);
        const userId = mongoUser._id;
        console.log("User id" + userId);
        storeData("userId", userId);
      })
      .then(() => {
        navigate("/viewer");
      })
      .then(() => {
        if (isTagManagerInitialized) {
          console.log("Sending conversion event");
          TagManager.dataLayer({
            dataLayer: {
              event: "conversion",
              send_to: "AW-11332542623/it-HCL6z3eAYEJ_B45sq",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert(`Failed to create account.\n\n${error.message}`);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      // Do whatever you want when the Enter key is pressed
      handleAccountCreation();
    }
  };

  const isValidSocialURL = (url) => {
    const linkedInPattern = /linkedin\.com\/in\/[a-zA-Z0-9_-]+(\/.*)?$/;

    const twitterPattern = /twitter\.com\/[a-zA-Z0-9_]+\/?$/;

    const xPattern = /x\.com\/[a-zA-Z0-9_]+\/?$/;

    return (
      linkedInPattern.test(url) ||
      twitterPattern.test(url) ||
      xPattern.test(url)
    );
  };

  return (
    <Box overflowY={{ base: "scroll", md: "hidden" }} h="100%">
      <Background backgroundImage={LoginBackground} noBlur={true}>
        <Header isLoggedOut={true} />
        <Container
          maxW="lg"
          py={{ base: "0", md: "3" }}
          // px={{ base: "0", sm: "8" }}
          m={0}
          ml={{ base: "none", md: "112px" }}
        >
          <Stack spacing="8">
            <Box
              my={{ base: "2" }}
              mx={{ base: "2" }}
              py={{ base: "8", sm: "8" }}
              px={{ base: "5", sm: "10" }}
              //bg={{ base: "transparent", sm: "bg.surface" }}
              boxShadow={{ base: "none", sm: "md" }}
              borderRadius={{ base: "24px", sm: "24px" }}
              bgColor={"white"}
            >
              <Stack spacing="6">
                {/* <Logo /> */}
                <Stack spacing="3" textAlign="left">
                  <Heading size="xl">Create an account</Heading>
                  <Text>
                    Already have an account?{" "}
                    <Link href="/login" textDecoration={"underline"}>
                      Log in now
                    </Link>
                  </Text>
                </Stack>
              </Stack>
              <Stack spacing="6" paddingTop={"16px"}>
                <Stack spacing="3">
                  <FormControl>
                    <FormLabel htmlFor="email">
                      <Flex>
                        <Text mr="4px">Email </Text>
                        <Text color="whiteAlpha.500">*</Text>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="email"
                      type="email"
                      bgColor="blackAlpha.200"
                      borderColor="blackAlpha.200"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="john@mystartup.com"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="url">
                      <Flex>
                        <Text mr="4px">Linkedin / Twitter URL </Text>
                        <Text color="whiteAlpha.500">*</Text>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="social"
                      type="url"
                      bgColor="blackAlpha.200"
                      borderColor="blackAlpha.200"
                      value={socialUrl}
                      onChange={(e) => setSocialUrl(e.target.value)}
                      placeholder="https://www.linkedin.com/in/yourname"
                    />
                  </FormControl>
                  <PasswordField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <PasswordField
                    retype={true}
                    value={retypePassword}
                    onChange={(e) => setRetypePassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Stack>
                <HStack justify="space-between">
                  <Checkbox
                    defaultChecked={false}
                    checked={termsAndService}
                    onChange={() => setTermsAndService(!termsAndService)}
                  ></Checkbox>
                  <Text fontSize={"14px"}>
                    I have read and agree to the Terms of Use and Customer
                    Privacy Policy.
                  </Text>
                </HStack>
                <Stack spacing="6">
                  <Button
                    bgImage="linear-gradient(to right, #80EFAC, #80EFEF)"
                    onClick={handleAccountCreation}
                    borderRadius={"12px"}
                  >
                    Create your account
                  </Button>
                  <Text textAlign={"center"} fontSize="14px">
                    <Link
                      href="https://builduncapped.com/terms"
                      textDecoration={"underline"}
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      href="https://builduncapped.com/privacy"
                      textDecoration={"underline"}
                    >
                      Privacy Policy
                    </Link>
                  </Text>
                  {/* <HStack>
                <Divider />
                <Text textStyle="sm" whiteSpace="nowrap" color="whiteAlpha.700">
                  or continue with
                </Text>
                <Divider />
              </HStack>
              <OAuthButtonGroup /> */}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Background>
    </Box>
  );
};

export default SignUp;
