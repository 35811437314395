import getCourseFromCourseId from "../../CourseViewer/utils/getCourseFromCourseId";

export const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_COMPLETED_COURSE":
      const { courseId, lessonId } = action;

      if (state.user && !state.user.courses) {
        console.error("Tried to complete course on non existent user");
        return { ...state };
      }

      let course = getCourseFromCourseId(state.user.courses, courseId);

      let completedLessons = [...course.completedLessons];

      if (completedLessons.indexOf(lessonId) !== -1) {
        return {
          ...state,
        };
      }
      completedLessons.push(lessonId);
      var newState = { ...state };

      getCourseFromCourseId(
        newState.user.courses,
        courseId
      ).completedLessons = completedLessons;
      return newState;
    case "LOADED_USER":
      return {
        ...state,
        user: { ...action.user },
      };
    case "RESET_USER":
      return {
        ...state,
        user: { ...initialState.user },
      };
    default:
      return state;
  }
};

export const initialState = {
  user: {
    _id: "",
    isEphemeral: true,
    courses: [],
    streak: 0,
  },
};
