import "./App.css";
import React, { useContext, useEffect } from "react";
import CourseBuilder from "./components/CourseBuilder/CourseBuilder";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Settings from "./CourseViewer/Settings";
import fetchUserAndDispatch from "./contexts/User/fetchUserAndDispatch";
import { UserContext } from "./contexts/User";
import Stats from "./components/Stats/Stats";
import ChatListView from "./components/Messenger/ChatListView";
import withAuthentication from "./auth/withAuthentication";
import AuthUserContext from "./auth/AuthUserContext";
import Background from "./Background";
import NewViewer from "./NewViewer";
import { Box } from "@chakra-ui/react";
import NewLoginScreen from "./login/NewLogin";
import SignUp from "./login/SignUp";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

export let isTagManagerInitialized = false;

const tagManagerArgs = {
  gtmId: "AW-11332542623",
};

if (window.location.hostname !== "localhost") {
  // Google Analytics
  ReactGA.initialize("G-FF80RZYZZE", {
    debug: process.env.NODE_ENV !== "production",
  });
  // Google Ads
  TagManager.initialize(tagManagerArgs);
  isTagManagerInitialized = true;
} else {
  console.log("Localhost detected, not initializing Google Analytics.");
}

const MainContent = () => {
  const navigate = useNavigate();
  const authUser = useContext(AuthUserContext);
  const [, userDispatch] = useContext(UserContext);

  useEffect(() => {
    if (authUser) {
      fetchUserAndDispatch(userDispatch);
    } else {
      if (window.location.pathname !== "/signup") {
        navigate("/login");
      }
    }
  }, [authUser, navigate, userDispatch]);

  //
  return (
    <Box
      height={"100vh"}
      width={"100vw"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Routes>
        <Route path="/" element={<NewLoginScreen />} />
        <Route path="/viewer" element={<NewViewer />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/login" element={<NewLoginScreen />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/editCourse" element={<CourseBuilder />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/conversations" element={<ChatListView />} />
      </Routes>
    </Box>
  );
};

const MainComponentWrapper = withAuthentication(MainContent);

function App() {
  return (
    <BrowserRouter>
      <Background>
        <MainComponentWrapper />
      </Background>
    </BrowserRouter>
  );
}

export default App;
