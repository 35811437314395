export default function getCompletedLessonsFromWatched(watchedLessons) {
  let completedLessons = [];
  if (watchedLessons === undefined || watchedLessons === null)
    return completedLessons;
  for (let index in watchedLessons) {
    if (watchedLessons[index].completed) {
      completedLessons.push(watchedLessons[index].lessonId);
    }
  }
  return completedLessons;
}
