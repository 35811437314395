const { REACT_APP_API_URL } = process.env;

export async function GetCourse(courseId) {
  const res = await fetch(
    `${REACT_APP_API_URL}.netlify/functions/getCourse?courseId=${courseId}`
  );
  const json = await res.json();

  return json;
}

export async function GetSignedUrl(courseId, fileName) {
  const res = await fetch(
    `${REACT_APP_API_URL}.netlify/functions/getSignedURL?fileName=${fileName}`
  );
  const json = await res.json();

  return json;
}

// email user the file as a post request
export async function EmailFile(courseId, fileName) {
  const res = await fetch(
    `${REACT_APP_API_URL}.netlify/functions/emailUserFile`,
    {
      method: "POST",
      body: JSON.stringify({
        courseId,
        fileName,
      }),
    }
  );
  const json = await res.json();

  return json;
}
