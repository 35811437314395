import getAuthHeaders from "./AuthHeaders";
const { REACT_APP_API_URL } = process.env;

export async function GetConversations() {
  const authHeaders = await getAuthHeaders();

  const requestOptions = {
    method: "GET",
    headers: authHeaders,
  };

  const res = await fetch(
    `${REACT_APP_API_URL}.netlify/functions/getConversationsForCoach`,
    requestOptions
  );

  const json = await res.json();

  console.log("GetConversations", json);

  return json;
}

export async function CoachSendMessage(conversationId, message) {
  try {
    const authHeaders = await getAuthHeaders();

    const requestOptions = {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify({
        conversationId,
        message,
      }),
    };

    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/coachSendMessage`,
      requestOptions
    );
    const json = await res.json();

    return json;
  } catch (e) {
    console.error(e);
  }
}
