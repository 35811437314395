import { auth } from "../firebase";

async function getAuthHeaders() {
  const authUser = auth.currentUser;

  if (!authUser) throw new Error("User is not authenticated");
  const token = await authUser.getIdToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
}

export default getAuthHeaders;
