import * as UserApi from "../api/UserApi";
import { ADD_TIME_TO_LESSON } from "../contexts/actions";

export default async function updateWatchTimeAndDispatch(
  lessonId,
  watchTimeSeconds,
  watchPositionSeconds,
  durationSeconds,
  completed,
  dispatch
) {
  await UserApi.UpdateWatchedTimeLesson(
    lessonId,
    watchTimeSeconds,
    watchPositionSeconds,
    durationSeconds,
    completed
  );

  dispatch({
    type: ADD_TIME_TO_LESSON,
    lessonId,
    watchTimeSeconds,
    watchPositionSeconds,
    completed,
    durationSeconds,
  });
}
