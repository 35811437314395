import React from "react";
import { Box } from "@chakra-ui/react";
import defaultBackground from "./defaultBackground.png";

function Background({
  children,
  backgroundImage = defaultBackground,
  noBlur = false,
}) {
  return (
    <Box
      pos="relative"
      minH="100vh"
      width="100vw"
      margin={0} // Resetting margin
      padding={0} // Resetting padding
      overflow="hidden" // Ensuring no overflow
    >
      {/* Pseudo-element for the background image */}
      <Box
        as="canvas"
        pos="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        width="100%"
        height="100%"
        id="canvasElement"
        backgroundSize="cover"
        backgroundImage={`url(${backgroundImage})`}
        zIndex={noBlur ? -1 : -2}
      />

      {/* Pseudo-element for the background color */}
      <Box
        content='""'
        pos="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        backgroundColor={noBlur ? null : "rgba(0, 0, 0, 0.4)"} // rgba(32, 8, 50, 0.7)
        backdropFilter={noBlur ? null : "blur(40px)"}
        zIndex="-1"
      />

      {children}
    </Box>
  );
}

export default Background;
