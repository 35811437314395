import React from "react";
import { StyledPaper } from "../../components/CourseBuilder/style";
import CustomDivider from "../../components/CustomDivider";
import { auth } from "../../firebase";
import { removeData } from "../../storage/storage";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/User";

export default function Settings() {
  const navigate = useNavigate();
  const [, userDispatch] = React.useContext(UserContext);

  const signOut = () => {
    console.log("Signing out...");
    auth
      .signOut()
      .then(() => {
        removeData("userId");
      })
      .then(() => {
        console.log("User signed out successfully");
        userDispatch({ type: "RESET_USER" });
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      <div style={{ display: "flex" }}>
        <StyledPaper style={{ minWidth: 415 }}>
          <CustomDivider color={"#A8A8A854"} />
          <div style={{ color: "white", cursor: "pointer" }} onClick={signOut}>
            {" "}
            Sign out{" "}
          </div>
          <CustomDivider color={"#A8A8A854"} />
        </StyledPaper>
      </div>
    </div>
  );
}
