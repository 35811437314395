import React from "react";
const CustomDivider = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      border: 0,
      height: 1,
      width: "100%",
    }}
  />
);

export default CustomDivider;
