import { ADD_TIME_TO_LESSON, LOADED_PROGRESS, COMPLETE_QUIZ } from "../actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case ADD_TIME_TO_LESSON:
      const {
        lessonId,
        watchTimeSeconds,
        watchPositionSeconds,
        completed,
        durationSeconds,
      } = action;

      var watchedLesson =
        state.watchedLessons.find(
          (watchedLesson) => watchedLesson.lessonId === lessonId
        ) || null;

      if (watchedLesson === null) {
        watchedLesson = {
          lessonId,
          watchTimeSeconds: 0,
          watchPositionSeconds: 0,
          durationSeconds: 0,
          completed: false,
        };
        state.watchedLessons.push(watchedLesson);
      }

      watchedLesson.watchTimeSeconds += watchTimeSeconds;
      watchedLesson.watchPositionSeconds = watchPositionSeconds;
      watchedLesson.completed = completed || watchedLesson.completed;
      watchedLesson.durationSeconds = durationSeconds;

      var newState = { ...state };
      newState.watchedLessons = [...state.watchedLessons];

      return newState;
    case LOADED_PROGRESS:
      const watchedLessons = action.progress?.watchedLessons || [];
      const quizAttempts = action.progress?.quizAttempts || [];
      return {
        ...state,
        watchedLessons: [...watchedLessons],
        quizAttempts: [...quizAttempts],
      };
    case COMPLETE_QUIZ:
      const { moduleId, userResponses, totalQuestions, numberCorrect } = action;

      var quizAttempt = state.quizAttempts.find(
        (quizAttempt) => quizAttempt.moduleId === moduleId
      );

      if (quizAttempt) {
        console.error("Quiz attempt already exists", quizAttempt);
        return state;
      }

      const newQuizAttempt = {
        moduleId,
        numberCorrect,
        numberIncorrect: totalQuestions - numberCorrect,
        totalQuestions: totalQuestions,
        userAnswers: userResponses,
        completed: true,
      };

      const progress = {
        ...state,
        quizAttempts: [...state.quizAttempts, newQuizAttempt],
      };

      return progress;
    default:
      return state;
  }
};

export const initialState = {
  watchedLessons: [],
  quizAttempts: [],
};
