export const storeData = (key, value) => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem(key, jsonValue);
  return value;
};

export const getData = (key) => {
  const jsonValue = localStorage.getItem(key);
  return jsonValue != null ? JSON.parse(jsonValue) : null;
};

export const removeData = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (exception) {
    return false;
  }
};
