import styled from "@emotion/styled";

export const StyledPaper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: rgba(27, 27, 28, 0.6);
  border-radius: 30px;
  padding: 25px;
  opacity: 0.9;
  margin: 20px;
`;

export const ButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const StyledInput = styled.input`
  border: 2px solid white;
  border-width: 1px;
  border-radius: 10px;
  background-color: transparent;
  color: white;
`;

export const StyledTextArea = styled.textarea`
  border: 2px solid white;
  border-width: 1px;
  border-radius: 10px;
  background-color: transparent;
  color: white;
`;
