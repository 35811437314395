import React, { useState } from "react";
import { StyledInput, StyledTextArea } from "./style";
import { Button } from "@mui/material";
import { ButtonHolder } from "./style";
import { LessonUploader } from "./LessonUploader";
import "./style.css";

const CourseItemEditor = ({
  item,
  onSave,
  onDelete,
  unSelectItem,
  uploader = false,
}) => {
  const [itemName, setItemName] = useState(item.name);
  const [itemDescription, setItemDescription] = useState(item.description);

  const handleNameChange = (e) => {
    setItemName(e.target.value);
    //onChange({ ...module, name: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setItemDescription(e.target.value);
    //onChange({ ...module, description: e.target.value });
  };

  const handleSaveClick = () => {
    onSave({
      ...item,
      name: itemName,
      description: itemDescription,
    });
  };

  const handleDeleteClick = () => {
    onDelete(item);
  };

  const handleDiscardClick = () => {
    setItemName(item.name);
    setItemDescription(item.description);
    unSelectItem();
  };

  const handleUploadSuccess = ({ url, thumbnail }) => {
    item["url"] = url;
    item["thumbnail"] = thumbnail;
  };

  console.log("ITEMN", item);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <StyledInput
        type="text"
        style={{ width: "100%", height: 30, marginBottom: 20 }}
        value={itemName}
        onChange={handleNameChange}
      />
      <StyledTextArea
        type="text"
        style={{ width: "100%", height: 100, marginBottom: 10 }}
        value={itemDescription}
        onChange={handleDescriptionChange}
      />
      {uploader && (
        <LessonUploader
          setVideoMetadata={handleUploadSuccess}
          initialVideoUrl={item["url"]}
        />
      )}
      <div
        style={{
          fontSize: 12,
          color: "#1976d2",
          width: "fit-content",
          marginLeft: "auto",
        }}
        onClick={handleDiscardClick}
      >
        {" "}
        Discard Changes
      </div>
      <ButtonHolder>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </ButtonHolder>
    </div>
  );
};

export default CourseItemEditor;
