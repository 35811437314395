export const reducer = (state, action) => {
  switch (action.type) {
    case "LOADED_COURSE":
      return {
        ...state,
        activeCourse: { ...action.course },
      };
    default:
      return state;
  }
};

export const initialState = {
  activeCourse: {
    _id: "",
    title: "",
    modules: [],
  },
};
