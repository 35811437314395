import React from "react";
import ReactDOM from "react-dom/client";
import "typeface-inter";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./contexts/User";
import { ProgressProvider } from "./contexts/Progress";
import { CourseProvider } from "./contexts/Course";
import { ChakraProvider } from "@chakra-ui/react";

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  textStyles: {
    moduleHeader: {
      color: "whiteAlpha.900",
      //fontFamily: "Inter",
      fontSize: { base: 16, md: 16 },
      fontWeight: "semibold",
    },
    textSm: {
      color: "whiteAlpha.900",
      //fontFamily: "Inter",
      fontWeight: "semibold",
      fontSize: { base: 14, md: 20 },
    },
  },
  fonts: {
    body: "Inter, system-ui, sans-serif",
    heading: "Inter, Arial, serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <ProgressProvider>
      <UserProvider>
        <CourseProvider>
          <App />
        </CourseProvider>
      </UserProvider>
    </ProgressProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
