export const findNextLesson = (modules, lessonId) => {
  for (let moduleIndex = 0; moduleIndex < modules.length; moduleIndex++) {
    const lessons = modules[moduleIndex].lessons;

    for (let lessonIndex = 0; lessonIndex < lessons.length; lessonIndex++) {
      if (lessons[lessonIndex]._id === lessonId) {
        const nextLessonIndex = lessonIndex + 1;

        if (lessons[nextLessonIndex]) {
          // Return next lesson in the current module
          return lessons[nextLessonIndex];
        } else if (
          modules[moduleIndex + 1] &&
          modules[moduleIndex + 1].lessons.length > 0
        ) {
          // Return the first lesson of the next module
          return modules[moduleIndex + 1].lessons[0];
        }
      }
    }
  }
  // If it's the last lesson of all modules, return null
  return null;
};
