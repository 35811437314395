import * as UserApi from "../../api/UserApi";
import { LOADED_PROGRESS } from "../actions";
// This function gets the local userId, if it doesnt exist it
// makes a new userId and stores it. It also stores that user in state.user object
export default async function fetchProgressAndDispatch(dispatch) {
  var progress = await UserApi.GetProgress();
  if (progress != null) {
    dispatch({
      type: LOADED_PROGRESS,
      progress,
    });
  }
}
