import React from "react";
import { StyledPaper } from "../CourseBuilder/style";

const Stats = () => {
  return (
    <StyledPaper>
      <h1 style={{ color: "white", weight: 600, fontSize: 20 }}>Stats</h1>
    </StyledPaper>
  );
};

export default Stats;
