import React, { useContext } from "react";
import { useState, useEffect } from "react";
import * as ConversationsApi from "../../api/Conversations";
import { CourseContext } from "../../contexts/Conversations";
import { StyledPaper } from "../CourseBuilder/style";
import withAdminOnly from "../../admin/withAdminOnly";
import AuthUserContext from "../../auth/AuthUserContext";

const boxStyle = {
  borderWidth: 1,
  borderColor: "grey",
  borderStyle: "solid",
  padding: 20,
  borderRadius: 10,
  margin: 10,
  boxShadow: "10px 5px 5px black",
};

const styledButton = {
  width: "fit-content",
  marginTop: 10,
  backgroundColor: "transparent",
  color: "#90caf9",
  border: "solid",
  borderRadius: "4px",
  borderWidth: "1px",
  borderColor: "#90caf9",
  padding: "8px 16px",
  fontSize: "16px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};

function ChatListView() {
  const [courseState] = React.useContext(CourseContext);
  const authUser = useContext(AuthUserContext);

  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (courseState != null && authUser !== null) {
        const response = await ConversationsApi.GetConversations();
        const { conversations } = response;
        console.log("conversations", conversations);
        setConversations(conversations);
      }
    };
    fetchData();
  }, [courseState, authUser]);

  const handleQuickMessageSend = async (conversation) => {
    console.log("Quick send");
    await ConversationsApi.CoachSendMessage(
      conversation._id,
      conversation.recommendedResponse
    );

    for (let index in conversations) {
      if (conversations[index]._id === conversation._id) {
        conversations[index].messages.push({
          sender: "coach",
          message: conversation.recommendedResponse,
          timestamp: Date.now(),
        });
        break;
      }
    }
    setConversations([...conversations]);
  };

  // only show conversations where the last message was from a student
  const filteredConversations = conversations.filter((conversation) => {
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    return lastMessage.sender === "student";
  });

  return (
    <StyledPaper>
      <h1 style={{ color: "white", weight: 600, fontSize: 20 }}>
        Conversations
      </h1>
      <div>
        {filteredConversations.map((conversation, index) => {
          const lastMessage = "test";
          return (
            <div style={boxStyle} key={conversation._id || index}>
              <div style={{ color: "white", fontWeight: "bold" }}>
                {conversation.studentId}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    color: "white",
                    flex: 1,
                    borderWidth: 1,
                    borderColor: "white",
                    borderStyle: "solid",
                    padding: 10,
                    borderRadius: 5,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontSize: 12, marginBottom: 5 }}>summary</div>
                  <div>{conversation.summary}</div>
                </div>
                <div
                  style={{
                    color: "white",
                    flex: 1,
                    borderWidth: 1,
                    borderColor: "white",
                    borderStyle: "solid",
                    padding: 10,
                    borderRadius: 5,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontSize: 12, marginBottom: 5 }}>
                    {" "}
                    last message{" "}
                  </div>
                  <div>{lastMessage}</div>
                </div>
                <div
                  style={{
                    color: "white",
                    flex: 1,
                    borderWidth: 1,
                    borderColor: "white",
                    borderStyle: "solid",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <div style={{ fontSize: 12, marginBottom: 5 }}>
                    Recommended Reply
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{conversation.recommendedResponse}</div>
                    <button
                      style={styledButton}
                      variant="contained"
                      onClick={() => handleQuickMessageSend(conversation)}
                    >
                      Quick Send
                    </button>
                  </div>
                </div>
                <button style={styledButton} variant="contained">
                  View full conversation
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </StyledPaper>
  );
}

export default withAdminOnly(ChatListView);
