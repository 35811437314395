import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/User";
import React from "react";

export default function withAdminOnly(Component) {
  return function (props) {
    const [userState] = React.useContext(UserContext);
    const navigate = useNavigate();

    const user = userState.user;

    React.useEffect(() => {
      if (user._id !== "") {
        console.log("createdCourses", user.createdCourses);
        if (!user.createdCourses || user.createdCourses.length === 0) {
          navigate("/viewer");
        }
      }
    }, [user, navigate]);

    return <Component {...props} />;
  };
}
