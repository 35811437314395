import getAuthHeaders from "./AuthHeaders";
const { REACT_APP_API_URL } = process.env;

export async function GetUserWithUpdatedStreak() {
  try {
    console.log("GetUserWithUpdatedStreak");
    const timezoneOffset = new Date().getTimezoneOffset();

    const authHeaders = await getAuthHeaders();

    const requestOptions = {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify({ timezoneOffset }),
    };

    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/getUserWithUpdatedStreak`,
      requestOptions /*, auth args */
    );

    if (!res.ok) {
      const errorData = await res.json();
      console.error("Error getting user with updated streak", errorData);
      throw new Error("Error getting user with updated streak");
    }

    const json = await res.json();
    return json;
  } catch (e) {
    throw e;
  }
}

// userId, lesson, timezoneOffset, watchTimeSeconds, completed
export async function UpdateWatchedTimeLesson(
  lessonId,
  watchTimeSeconds,
  watchPositionSeconds,
  durationSeconds,
  completed
) {
  try {
    const timezoneOffset = new Date().getTimezoneOffset();

    const requestOptions = {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify({
        lessonId,
        timezoneOffset,
        watchTimeSeconds,
        watchPositionSeconds,
        durationSeconds,
        completed,
      }),
    };

    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/updateWatchTimeLesson`,
      requestOptions
    );
    const json = await res.json();

    return json;
  } catch (e) {
    return {}; // it wont actually update backend but doesnt break the app
  }
}

export async function CompleteQuiz(
  moduleId,
  userResponses,
  totalQuestions,
  numberCorrect
) {
  try {
    const requestOptions = {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify({
        moduleId,
        userResponses,
        totalQuestions,
        numberCorrect,
      }),
    };

    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/updateEntireQuizAttempt`,
      requestOptions
    );
    const json = await res.json();

    return json;
  } catch (e) {
    return {}; // it wont actually update backend but doesnt break the app
  }
}

export async function GetProgress() {
  try {
    const requestOptions = {
      method: "GET",
      headers: await getAuthHeaders(),
    };
    console.log(REACT_APP_API_URL);
    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/getProgress`,
      requestOptions /*, auth args */
    );

    const json = await res.json();
    const parsedJson = JSON.parse(JSON.stringify(json));

    return parsedJson;
  } catch (e) {
    throw e;
  }
}

export async function GetUserAccount() {
  console.log("GetUserAccount");
  try {
    const requestOptions = {
      method: "GET",
      headers: await getAuthHeaders(),
    };

    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/getUserAccount`,
      requestOptions /*, auth args */
    );

    const json = await res.json();
    const parsedJson = JSON.parse(JSON.stringify(json));

    return parsedJson;
  } catch (e) {
    throw e;
  }
}

export async function CreateUserAccount(
  email,
  firebaseUserId,
  socialUrl,
  marketingEmails = false
) {
  // endpoint createUserUncapped
  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        email,
        firebaseUserId,
        socialUrl,
        marketingEmails,
      }),
    };

    const res = await fetch(
      `${REACT_APP_API_URL}.netlify/functions/createUserUncapped`,
      requestOptions /*, auth args */
    );

    const json = await res.json();
    const parsedJson = JSON.parse(JSON.stringify(json));

    return parsedJson.user;
  } catch (e) {
    throw e;
  }
}
