// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBiAVUcY-ZOOkqchHa13TxtXA7h5OARIXY",
  authDomain: "pairai-bc6d2.firebaseapp.com",
  projectId: "pairai-bc6d2",
  storageBucket: "pairai-bc6d2.appspot.com",
  messagingSenderId: "934705769015",
  appId: "1:934705769015:web:e70603b3fcb2b01f2fadb2",
  measurementId: "G-PRBP1SJ2DR",
};

// Initialize Firebase
//let app;
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const auth = firebase.auth();

export { auth };
