import * as CourseApi from "../../api/CourseApi";

// This function gets the local userId, if it doesnt exist it
// makes a new userId and stores it. It also stores that user in state.user object
export default async function fetchCourseAndDispatch(dispatch, courseId) {
  let course = await CourseApi.GetCourse(courseId);
  if (course != null && course._id != null) {
    dispatch({ type: "LOADED_COURSE", course });
  }
}
