// contexts/User/index.jsx

import React from "react";
import { reducer, initialState } from "./reducer";

console.log("Initial state", initialState);

export const ProgressContext = React.createContext([initialState, () => null]);

export const ProgressProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ProgressContext.Provider value={[state, dispatch]}>
      {children}
    </ProgressContext.Provider>
  );
};
