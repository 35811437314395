import React, { useEffect, useState, useRef } from "react";
import { ProgressContext } from "../../contexts/Progress";
import updateWatchTimeAndDispatch from "../../utils/updateWatchTimeAndDispatch";
import HLSPlayer from "../../components/CourseBuilder/HLSPlayer";
import { Box } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import getWatchedLesson from "../../CourseViewer/utils/getWatchedLesson";

const NewLessonViewer = ({ lesson, playNextLesson }) => {
  const videoRef = useRef(null);
  const [state, dispatch] = React.useContext(ProgressContext);
  const completedAlready = useRef(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const cleanup = () => {
      //console.log("Cleaning up");
      if (intervalId) {
        clearInterval(intervalId);
      }

      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", function () {});
      }
    };
    // Set up interval when video starts playing

    if (videoRef.current != null) {
      cleanup();
      const id = setInterval(() => {
        const video = videoRef.current;
        if (video && !video.paused) {
          console.log("Its been 10 seconds yo!!!!!");

          ReactGA.event({
            category: "Video",
            action: "Seconds Watched",
            label: lesson._id,
            value: 10,
          });

          const completed = video.currentTime >= video.duration * 0.8;
          updateWatchTimeAndDispatch(
            lesson._id,
            10,
            video.currentTime,
            video.duration,
            completed,
            dispatch
          );
          if (completed && !completedAlready.current) {
            completedAlready.current = true;
          }
        }
      }, 10000); // 10000 milliseconds = 10 seconds

      setIntervalId(id); // Store intervalId in state so we can clear it later
      videoRef.current &&
        videoRef.current.addEventListener("timeupdate", function () {
          if (!videoRef.current) {
            return;
          }
          const progress =
            videoRef.current.currentTime / videoRef.current.duration;
          if (progress >= 0.8) {
            console.log(
              "The video is at least 80% completed.",
              completedAlready.current
            );
            if (!completedAlready.current) {
              ReactGA.event({
                category: "Video",
                action: "completed",
                label: lesson._id,
                value: true,
              });

              updateWatchTimeAndDispatch(
                lesson._id,
                10,
                videoRef.current.currentTime,
                videoRef.current.duration,
                true,
                dispatch
              );
              completedAlready.current = true;
            } else {
              cleanup();
            }
          }
        });
      return () => {
        cleanup();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson, dispatch]);

  let watchedLesson = getWatchedLesson(state.watchedLessons, lesson._id);

  let initialWatchPos = watchedLesson?.watchPositionSeconds || 0;

  if (initialWatchPos > lesson.durationSeconds - 10) {
    initialWatchPos = 0;
  }

  return (
    <Box h="100%">
      <div style={{ color: "white", height: "100%" }}>
        {lesson && (
          <HLSPlayer
            muxPlaybackId={lesson.muxPlaybackId}
            externalVideoRef={videoRef}
            initialWatchPos={initialWatchPos}
            playNextLesson={playNextLesson}
          />
        )}
      </div>
      {lesson && (lesson.files || []).length > 0 && (
        <div>
          <div style={{ color: "white" }}>Files</div>
          <div>
            {(lesson.files || []).map((file) => (
              <div style={{ color: "white" }} key={file.name}>
                {file.fileName}{" "}
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={file.name + "a"}
                >
                  Download
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </Box>
  );
};

export default NewLessonViewer;
