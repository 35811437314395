import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import AuthUserContext from "./AuthUserContext";

const withAuthentication = (Component) => {
  return (props) => {
    const [state, setState] = useState({
      authUser: null,
      isLoading: true,
    });

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          console.log("withAuthentication: auth success");
          setState({ authUser, isLoading: false });
        } else {
          console.log("withAuthentication: No user signed in");
          setState({ authUser: null, isLoading: false });
        }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, []);

    const { authUser, isLoading } = state;

    return (
      <AuthUserContext.Provider value={authUser}>
        {isLoading ? null : <Component {...props} />}
      </AuthUserContext.Provider>
    );
  };
};

export default withAuthentication;
