"use client";

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  //MenuDivider,
  useColorModeValue,
  Stack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import Logo from "../../assets/logo.svg";
import LogoMobile from "../../assets/fav-32.svg";
import SidebarIcon from "../../assets/sidebarIcon.svg";
import SidebarInactive from "../../assets/sidebarInactive.svg";
import { auth } from "../../firebase";
import { removeData } from "../../storage/storage";
import { UserContext } from "../../contexts/User";
import { useNavigate } from "react-router-dom";
import React from "react";
import ReactGA from "react-ga4";

const Links = []; //["Dashboard", "Projects", "Team"];

export const HeaderHeight = 60;

const NavLink = (props) => {
  const { children } = props;

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={"#"}
    >
      {children}
    </Box>
  );
};

//isLoggedIn has a different style and no buttons

export default function Header({
  isOpen,
  onOpen,
  onClose,
  lesson,
  isLoggedOut = false,
}) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [, userDispatch] = React.useContext(UserContext);
  const navigate = useNavigate();

  const signOut = () => {
    ReactGA.event({
      category: "Navigation",
      action: "Sign out",
    });

    console.log("Signing out...");
    auth
      .signOut()
      .then(() => {
        removeData("userId");
      })
      .then(() => {
        console.log("User signed out successfully");
        userDispatch({ type: "RESET_USER" });
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <Box maxH={HeaderHeight} bg={isLoggedOut ? "black" : null}>
      <Box px={5}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          {/* <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          /> */}
          <HStack flex={1}>
            {isMobile ? (
              <Image src={LogoMobile} height="30px" />
            ) : (
              <Image src={Logo} height="30px" />
            )}
            {!isLoggedOut && (
              <IconButton
                ml={{ base: "3", md: "8" }}
                display={{ base: "block", md: "block" }}
                aria-label="Sidebar Icon"
                icon={
                  <Image
                    src={isOpen ? SidebarIcon : SidebarInactive}
                    h="40px"
                  />
                }
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Sidebar clicked",
                    value: !isOpen, // what they are making it
                  });

                  if (isOpen) {
                    onClose();
                    return;
                  }
                  onOpen();
                }}
                _hover={{ bg: "gray.400" }}
                cursor="pointer"
                bg={"transparent"}
                borderRadius={"10px"}
              />
            )}
          </HStack>
          {!isLoggedOut && (
            <Flex justifyContent="center" alignItems="center">
              <Text
                fontSize={{ base: "0", md: "16" }}
                my={4}
                color={"white"}
                fontWeight={"600"}
              >
                {lesson?.title || ""}
              </Text>
            </Flex>
          )}
          {!isLoggedOut && (
            <Flex alignItems={"center"} flex={1} justifyContent={"end"}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    bg={"whiteAlpha.200"}
                    _hover={{
                      bg: "whiteAlpha.300",
                    }}
                  />
                </MenuButton>
                <MenuList>
                  {/* <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider /> */}
                  <MenuItem onClick={signOut}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          )}
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
