// contexts/User/index.jsx

import React from "react";
import { reducer, initialState } from "./reducer";

export const CourseContext = React.createContext([initialState, () => null]);

export const CourseProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CourseContext.Provider value={[state, dispatch]}>
      {children}
    </CourseContext.Provider>
  );
};
