import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  StyledInput,
  StyledTextArea,
  StyledPaper,
  ButtonHolder,
} from "./style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import "./style.css";
import CourseItemEditor from "./CourseItemEditor";
import { Button } from "@mui/material";
import CustomDivider from "../CustomDivider";
import withAdminOnly from "../../admin/withAdminOnly";

const CourseBuilder = () => {
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [lessonEditingIndex, setLessonEditingIndex] = useState(-1);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingCourseDescription, setEditingCourseDescription] = useState(
    false
  );
  const [editingCourseName, setEditingCourseName] = useState(false);
  const [selectedModule, setSelectedModule] = useState(-1);
  const [modules, setModules] = useState([
    {
      name: "Test Module",
      lessons: [
        {
          name: "test lesson 1",
          description: "test description 1",
          url:
            "https://customer-jxvq7jh5xtsbpvhm.cloudflarestream.com/4b29d7cc23faf0e6edb07c00ba20fd14/manifest/video.m3u8",
          thumbnail: null,
        },
        {
          name: "test lesson 2",
          description: "test description 2",
          url: null,
          thumbnail: null,
        },
      ],
    },
    { name: "Test Module 2", lessons: [] },
  ]);

  const handleCourseNameSave = () => {
    // Send the updated values to the API or database
    // ...
    setEditingCourseName(false);
  };

  const handleCourseDescriptionSave = () => {
    // Send the updated values to the API or database
    // ...
    setEditingCourseDescription(false);
  };

  const handleSaveLesson = (index, newLesson) => {
    let updatedModules = [...modules];
    updatedModules[selectedModule].lessons[index] = newLesson;
    setModules(updatedModules);
    setLessonEditingIndex(-1);
  };

  function handleSave(index, newModule) {
    // Send the updated values to the API or database
    // ...
    let updatedModules = [...modules];
    updatedModules[index] = newModule;
    setModules(updatedModules);
    setSelectedModule(-1);
    setEditingIndex(-1);
  }

  const moduleOnClick = (item) => {
    setSelectedModule(item);
    setLessonEditingIndex(-1);
  };

  function handleDelete(moduleId) {
    // Remove the module from the API or database
    // ...
    // lessonEditingIndex
  }

  const addModule = () => {
    const newModule = { name: "New Module", lessons: [] };
    setModules([...modules, newModule]);
    setLessonEditingIndex(-1);
    setSelectedModule(-1);
  };

  const addLesson = () => {
    const newLesson = { name: "New Lesson", description: "" };
    let newModules = [...modules];
    newModules[selectedModule].lessons.push(newLesson);
    setModules(newModules);
    // lessonEditingIndex
  };

  return (
    <div style={{ color: "white", width: "100%" }}>
      <div
        style={{
          padding: 20,
          justifyContent: "center",
          display: "flex",
          maxWidth: 1500,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <StyledPaper>
          <div>
            <div style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
              Course Name
            </div>
            {!editingCourseName ? (
              <div className="moduleHeaderContainer">
                <div style={{ weight: 400, fontSize: 16 }}>{courseName}</div>
                <div
                  style={{ fontSize: 12, color: "#1976d2", cursor: "pointer" }}
                  onClick={() => setEditingCourseName(true)}
                >
                  {" "}
                  edit
                </div>
              </div>
            ) : (
              <>
                {" "}
                <StyledInput
                  type="text"
                  style={{ width: "100%", height: 30 }}
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />{" "}
                <ButtonHolder style={{ marginTop: 5 }}>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleCourseNameSave}
                  >
                    Save
                  </Button>
                </ButtonHolder>
              </>
            )}
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                marginTop: 25,
                marginBottom: 10,
              }}
            >
              Course Description
            </div>
            {!editingCourseDescription ? (
              <div className="moduleHeaderContainer">
                <div style={{ weight: 400, fontSize: 16 }}>
                  {courseDescription}
                </div>
                <div
                  style={{ fontSize: 12, color: "#1976d2", cursor: "pointer" }}
                  onClick={() => setEditingCourseDescription(true)}
                >
                  {" "}
                  edit
                </div>
              </div>
            ) : (
              <>
                {" "}
                <StyledTextArea
                  type="text"
                  style={{ width: "100%", height: 100 }}
                  value={courseDescription}
                  onChange={(e) => setCourseDescription(e.target.value)}
                />{" "}
                <ButtonHolder style={{ marginTop: 5 }}>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleCourseDescriptionSave}
                  >
                    Save
                  </Button>
                </ButtonHolder>
              </>
            )}

            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                marginTop: 25,
                marginBottom: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>Modules</div>
              <AddIcon
                className="cursor"
                color="primary"
                fontSize="large"
                onClick={addModule}
              />
            </div>
            <div className="moduleList">
              {modules.map((module, i) => (
                <div
                  className="moduleListItem"
                  onClick={() => moduleOnClick(i)}
                >
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    {editingIndex !== i ? (
                      <div className="moduleHeaderContainer">
                        <div style={{ weight: 400, fontSize: 16 }}>
                          {module.name}
                        </div>
                        <div
                          style={{ fontSize: 12, color: "#1976d2" }}
                          onClick={() => setEditingIndex(i)}
                        >
                          {" "}
                          edit
                        </div>
                      </div>
                    ) : (
                      <CourseItemEditor
                        item={module}
                        onSave={(newModule) => handleSave(i, newModule)}
                        onDelete={() => handleDelete(i)}
                        unSelectItem={() => setEditingIndex(-1)}
                      />
                    )}
                  </div>
                  <CustomDivider color={"#A8A8A854"} />
                </div>
              ))}
            </div>
          </div>
        </StyledPaper>
        <StyledPaper>
          {selectedModule !== -1 ? (
            <div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <ChevronLeftIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedModule(-1)}
                  />{" "}
                  <div style={{ marginLeft: 5 }}>Lesson </div>
                </div>
                <AddIcon
                  className="cursor"
                  color="primary"
                  fontSize="large"
                  onClick={addLesson}
                />
              </div>
              <div className="moduleList">
                {selectedModule !== -1 &&
                  modules[selectedModule].lessons.map((lesson, i) => (
                    <div className="moduleListItem">
                      {lessonEditingIndex !== i ? (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                        >
                          <div style={{ weight: 400, fontSize: 16 }}>
                            {lesson.name}
                          </div>
                          <div
                            style={{ fontSize: 12, color: "#1976d2" }}
                            onClick={() => setLessonEditingIndex(i)}
                          >
                            {" "}
                            edit
                          </div>
                        </div>
                      ) : (
                        <CourseItemEditor
                          item={lesson}
                          onSave={(newLesson) => handleSaveLesson(i, newLesson)}
                          onDelete={() => handleDelete(i)}
                          unSelectItem={() => setLessonEditingIndex(-1)}
                          uploader={true}
                        />
                      )}
                      <CustomDivider color={"#A8A8A854"} />
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              Click on a module to view lessons
            </div>
          )}
        </StyledPaper>
      </div>
    </div>
  );
};

export default withAdminOnly(CourseBuilder);
